import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Navbar';
import MapComponent from './MapComponent';
import About from './About';
import Contact from './Contact';
import Container from '@mui/material/Container';
import axios from "axios";
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import { CountertopsSharp } from '@mui/icons-material';

const getInitialSelectedTab = (killingsCount,disappearancesCount,arrestsCount) => {
  if (killingsCount > 0) {
    return "Killings"
  }
  if (disappearancesCount > 0) {
    return "Disappearances"
  }
  if (arrestsCount > 0) {
    return "Arrests"
  }
  return "Killings"
};

const getDotElement = (happening) => {
  if (happening == "Disappearance") {
    return <span style={{color: "#000000", marginRight: 3}}>•</span>
  }
  if (happening == "Killing") {
    return <span style={{color: "#cdc7e4", marginRight: 3}}>•</span>
  }
  if (happening == "Arrest") {
    return <span style={{color: "#ffbe05", marginRight: 3}}>•</span>
  }
}

const CountSection = ({selectedProvince, killingsCount, arrestsCount, disappearancesCount}) => {
  return (
    <Grid container justifyContent="space-around" spacing={0.5} xs={7} md={7} direction={{xs: "row", sm: "row", md: "row",}}>
    <Grid item xs={"auto"} md={"auto"}>
      <Typography variant='h5' style={{textAlign: "center"}}>{killingsCount}</Typography>
      <Typography variant='h6' style={{textAlign: "center", fontSize: "12px"}}>
      {getDotElement("Killing")}
        Killing{(killingsCount > 1 || killingsCount == 0) && "s"}
      </Typography>
    </Grid>
    <Grid item xs={"auto"} md={"auto"}>
      <Typography variant='h5' style={{textAlign: "center"}}>{disappearancesCount}</Typography>
      <Typography variant='h6' style={{textAlign: "center", fontSize: "12px"}}>
        {getDotElement("Disappearance")}
        Disappearance{(disappearancesCount > 1 || disappearancesCount == 0) && "s"}
      </Typography>
    </Grid>
    <Grid item xs={"auto"} md={"auto"}>
      <Typography variant='h5' style={{textAlign: "center"}}>{arrestsCount}</Typography>
      <Typography variant='h6' style={{textAlign: "center", fontSize: "12px"}}>              
      {getDotElement("Arrest")}
        Arrest{(arrestsCount > 1 || arrestsCount == 0) && "s"}
      </Typography>
    </Grid>
    </Grid>
  );
};

const ContentContainer = ({loadedContent, selectedProvince, selectedTab, onChangeTab}) => {
  let entries = [];
  let killingsCount = 0;
  let arrestsCount = 0;
  let disappearancesCount = 0;
  
  //console.log("Content")
  //console.log(entries)
  if (loadedContent != undefined) {
    entries = loadedContent.filter((entry) => {
      return entry.attributes.province == selectedProvince
    });
    disappearancesCount = entries.filter((entry) => {
      return entry.attributes.happening == "Disappearance"
    }).length;
    killingsCount = entries.filter((entry) => {
      return entry.attributes.happening == "Killing"
    }).length;
    arrestsCount = entries.filter((entry) => {
      return entry.attributes.happening == "Arrest"
    }).length;
  }

  if (selectedProvince == "About") {
    const totalKillingsCount = loadedContent.filter((entry) => {
      return entry.attributes.happening == "Killing"
    }).length;
    const totalDisappearancesCount = loadedContent.filter((entry) => {
      return entry.attributes.happening == "Disappearance"
    }).length;
    const totalArrestsCount = loadedContent.filter((entry) => {
      return entry.attributes.happening == "Arrest"
    }).length;

    return (
      <Container sx={{
        p: 2, 
        background: '#fff',
        width: '100%',
        height: '100%', 
        float:'left',
        overflow: 'scroll',
        paddingBottom: "80px",
        }}>
          <Grid container spacing={0}>
        <Grid item xs={5} md={5} style={{marginBottom: 30}}>
          <Typography style={{paddingTop: 9}} variant='h6' >About the Project</Typography>
        </Grid>
        
          <CountSection
            killingsCount={totalKillingsCount}
            disappearancesCount={totalDisappearancesCount}
            arrestsCount={totalArrestsCount}
          />
      </Grid>
          {
          entries.length > 0 && 
          <BlocksRenderer content={entries[0].attributes.content} />
          }
        </Container>
    )
  }

  return (
    <Container sx={{
      p: 2, 
      background: '#fff',
      width: '100%',
      height: '100%', 
      float:'left',
      overflow: 'scroll',
      paddingBottom: "80px",
      }}>
      <Grid container spacing={0}>
        <Grid item xs={5} md={5} style={{marginBottom: 30}}>
          <Typography style={{paddingTop: 9}} variant='h5' >{selectedProvince} Province</Typography>
        </Grid>
          <CountSection
            killingsCount={killingsCount}
            disappearancesCount={disappearancesCount}
            arrestsCount={arrestsCount}
          />
      </Grid>
      <ToggleButtonGroup
        color="primary"
        value={selectedTab == undefined ? getInitialSelectedTab(killingsCount,disappearancesCount,arrestsCount) : selectedTab}
        exclusive
        onChange={(e) => {onChangeTab(e.target.value)}}
        aria-label="Section"
        className="tabs"
      >
        {killingsCount > 0 && <ToggleButton className="tab" value="Killings">Killings</ToggleButton>}
        {disappearancesCount > 0 && <ToggleButton className="tab" value="Disappearances">Disappearances</ToggleButton>}
        {arrestsCount > 0 && <ToggleButton className="tab" value="Arrests">Arrests</ToggleButton>}
      </ToggleButtonGroup>
        {entries.filter((entry) => {
          let predicate = selectedTab == undefined ? getInitialSelectedTab(killingsCount,disappearancesCount,arrestsCount) : selectedTab;
          predicate = predicate.substring(0,predicate.length-1);
          // console.log(`pred: ${predicate}`)
          return entry.attributes.happening == predicate
        }).map((entry) => (<Accordion disableGutters
          elevation={0}
          sx={{
              '&:before': {
                  display: 'none',
              }
          }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${entry.id}-content`}
          id={`${entry.id}-content`}
        >
          
          <Typography className="personListName" variant='h6'>{getDotElement(entry.attributes.happening)}{entry.attributes.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BlocksRenderer content={entry.attributes.content} />
        </AccordionDetails>
      </Accordion>
      ))}
    </Container>
  );
};

export default ContentContainer;
