import React, { useState } from 'react';
import { AppBar, Button, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Typography, CssBaseline, useTheme, ThemeProvider, createTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

const Navbar = ({onClickAboutButton}) => {

  return (
    <div>
      <CssBaseline />
      <AppBar position="static" color="primary">
        <Toolbar>
        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          <img src="zantimes-logo.svg" alt="Zan Times Logo" style={{ height: '38px', marginLeft: 6, padding: 2 }} />
        </div>
        <Button 
          onClick={onClickAboutButton} 
          color="inherit"
          style={{
            textTransform: 'none',
            fontWeight: 800,
          }}
        >About</Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
