import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, useTheme, ThemeProvider, CssBaseline, Container, Button} from '@mui/material';
import Navbar from './Navbar';
import MapComponent from './MapComponent';
import About from './About';
import Contact from './Contact';
import ContentContainer from './ContentContainer';
import axios from "axios";
import Grid from '@mui/material/Grid';
import MapIcon from '@mui/icons-material/Map';
import NewspaperIcon from '@mui/icons-material/Newspaper';

const zanTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1d0056', // Dark blue
    },    
    secondary: {
      main: '#1d0056', // Dark blue
    },
  },
  typography: {
    fontFamily: [
      "Cambay",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(','),
  }
});

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

const getInitialSelectedTab = (loadedContent, selectedProvince) => {
  let entries = [];
  let killingsCount = 0;
  let arrestsCount = 0;
  let disappearancesCount = 0;

  if (loadedContent != undefined) {
    entries = loadedContent.filter((entry) => {
      return entry.attributes.province == selectedProvince
    });
    console.log("lc",entries)

    disappearancesCount = entries.filter((entry) => {
      return entry.attributes.happening == "Disappearance"
    }).length;
    killingsCount = entries.filter((entry) => {
      return entry.attributes.happening == "Killing"
    }).length;
    arrestsCount = entries.filter((entry) => {
      return entry.attributes.happening == "Arrest"
    }).length;

    if (killingsCount > 0) {
      return "Killings"
    }
    if (disappearancesCount > 0) {
      return "Disappearances"
    }
    if (arrestsCount > 0) {
      return "Arrests"
    }
  }
  return "Killings"
};

const STRAPI_API_TOKEN = process.env.REACT_APP_STRAPI_API_TOKEN;
var STRAPI_APP_URL = process.env.REACT_APP_STRAPI_APP_URL;


function App() {
  const [loadedContent, setLoadedContent] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("About");
  const [selectedTab, setSelectedTab] = useState("Killings");
  const [mapViewActive, setMapViewActive] = useState(false);
  const [error, setError] = useState(null);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  // keep track of window size
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChangeProvince = (newSelectedProvince) => {
    setSelectedProvince(newSelectedProvince);
    setMapViewActive(false);
    // reset the selected tab if necessary to fit the happenings in the new province
    setSelectedTab(getInitialSelectedTab(loadedContent, newSelectedProvince));
  }

  const handleChangeSelectedTab = (newSelectedTab) => {
    setSelectedTab(newSelectedTab);
  }

  const handleClickAboutButton = () => {
    setMapViewActive(false);
    setSelectedProvince("About");
  }
  
  const strapiAuthHeaders = {"Authorization":`Bearer ${STRAPI_API_TOKEN}`}
  if (STRAPI_APP_URL[STRAPI_APP_URL.length-1] == '/') {
    STRAPI_APP_URL = STRAPI_APP_URL.slice(0,STRAPI_APP_URL.length-1)
  }

  useEffect(() => {
    console.log("Making strapi call")
    const pageSize = 100;
    let page = 1;
    axios.get(`${STRAPI_APP_URL}/api/entries?pagination[pageSize]=${pageSize}&pagination[page]=${page}`, 
        {headers: strapiAuthHeaders},
      ).then((response) => {
      setLoadedContent(response.data.data);
    }).catch((e) => {
      console.log("Error loading:")
      console.log(e);
      setError(e);
    })
  }, []);

  if (error) {
    // Print errors if any
    return <div>An error occured: {error.message}</div>;
  }

  const mobileLayout = (      
    <Grid container spacing={0} direction={{xs: "column", md: "row",}} style={{height: '100vh'}}>
        <div style={{
          zIndex: 1000000000,
          margin: "10px auto 10px auto",
          position: "absolute",
          width: '100%',
          bottom: 0,
          height: 50,
          display:'flex', 
          flexDirection:'column'
        }} className="mobileToggleButtonContainer">
          
        <Button 
        onClick={() => {setMapViewActive(!mapViewActive)}} 
        color="inherit"
        variant="contained"
        disableElevation
        startIcon={mapViewActive ? <NewspaperIcon /> : <MapIcon />}
        className="toggleContentMapViewButton"
        style={{
          textTransform: 'none',
          fontWeight: 800,
          margin: "0px auto 0px auto",
          backgroundColor: "#ffbe05"
        }}
      ><span style={{paddingTop:4}}>{!mapViewActive ? "View Map" : "View Entries"}</span></Button>
      </div>
      { !mapViewActive ? 
        <Grid item xs={12} md={12} style={{height: "100%"}}>
          <ContentContainer 
            loadedContent={loadedContent} 
            selectedProvince={selectedProvince} 
            selectedTab={selectedTab}
            onChangeTab={handleChangeSelectedTab}
          />
        </Grid>
      :
        <Grid item xs={12} md={12} style={{height: "100%"}}>
          <MapComponent 
            loadedContent={loadedContent} 
            onChangeProvince={handleChangeProvince}
            selectedProvince={selectedProvince}
          />
        </Grid>
        }
    </Grid>
  );

  const desktopLayout = (
    <Grid container spacing={0} direction={{xs: "column", md: "row",}} style={{height: '100%'}}>
      <Grid item xs={5} md={5} style={{height: "100vh", boxShadow: '0px 0px 5px 5px #bbb', zIndex: 1500000,}}>
        <ContentContainer 
          loadedContent={loadedContent} 
          selectedProvince={selectedProvince} 
          selectedTab={selectedTab}
          onChangeTab={handleChangeSelectedTab}
        />
        </Grid>
      <Grid item xs={7} md={7} style={{height: "100vh"}}>
        <MapComponent 
          loadedContent={loadedContent} 
          onChangeProvince={handleChangeProvince}
          selectedProvince={selectedProvince}
        />
      </Grid>
    </Grid>
  ); 

  let selectedLayout = (windowDimensions.width >= 900) ? desktopLayout : mobileLayout;

  return (
    <ThemeProvider theme={zanTheme}>
      <CssBaseline />
      <Router>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
          <Navbar onClickAboutButton={handleClickAboutButton} />
          <div style={{ flexGrow: 1, overflow: 'auto' }}>
            <Routes>
              <Route path="/" element={selectedLayout} />
              <Route path="/home" element={<MapComponent />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
