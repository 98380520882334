import React, { useState, useEffect, useCallback } from 'react';
import { DeckGL } from '@deck.gl/react';
import { GeoJsonLayer } from '@deck.gl/layers';
import Map, { NavigationControl, MapProvider } from 'react-map-gl'; // Import Map and other controls if needed
import 'mapbox-gl/dist/mapbox-gl.css';
import ContentContainer from './ContentContainer';
import Box from '@mui/material/Box';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_KEY;

const MapComponent = ({loadedContent, selectedProvince, onChangeProvince}) => {
  const [viewport, setViewport] = useState({
    longitude: 67,
    latitude: 34,
    zoom: 5,
    pitch: 0,
    bearing: 0
  });

  const [geoJsonData, setGeoJsonData] = useState(null);

  useEffect(() => {
    // Assuming you have "map-data.geojson" in the public directory
    fetch(process.env.PUBLIC_URL + '/map-data.geojson')
      .then((res) => res.json())
      .then((data) => setGeoJsonData(data));
  }, []);

  const provinceIsSelected = (p) => {
    console.log("prov",selectedProvince)
    return selectedProvince == p.properties.name || p.properties.picked
  };

  const layers = geoJsonData ? [
    new GeoJsonLayer({
      id: 'geojson-layer',
      data: geoJsonData,
      pickable: true,
      filled: true,
      stroked: true,
      lineWidthMinPixels: 2,
      getFillColor: (f) => { 
        if (selectedProvince == f.properties.name) { 
          return [255,190,5,150]
        } else { 
          return [160, 160, 180, 90]
        }
      },
      getLineColor: (f) => { 
        if (provinceIsSelected(f)) { 
          return [255,190,5,255]
        } else { 
          return [29, 0, 86, 255]
        }
      },
      getRadius: 100,
      getLineWidth: 1000,
      getElevation: 30,
      pickable: true,
      autoHighlight: true,
      highlightColor: [255,190,5,150], // ffbe05
      onClick: ({object}) => { onChangeProvince(object.properties.name) },
      updateTriggers: {
        getFillColor: [selectedProvince],
        getLineColor: [selectedProvince]
      },
      minZoom: 5,
    })
  ] : [];

  const setViewportWithConstraints = (vp) => {
    setViewport({
      ...vp, 
      zoom: vp.zoom < 4 ? 4 : vp.zoom,
    })
  };

  return (
    <DeckGL
      initialViewState={viewport}
      controller={true}
      layers={layers}
      style={{position: "relative"}}
      viewState={viewport}
      onViewStateChange={e => setViewportWithConstraints(e.viewState)}
    >
      <Box style={
        {
          position:"absolute", 
          zIndex: 10000,
          padding: 7,
          paddingBottom: 2,
          borderRadius: 3,
          margin: 10,
          backgroundColor: 'rgba(228, 227, 232,0.9)',
          border: '2px solid rgba(29, 0, 86, 1.0)',
          fontWeight: 'bold',
          color: '#1d0056',
        }
      }>Public Afghan Women Archive</Box>
          <Map
            mapboxAccessToken={MAPBOX_TOKEN}
            mapStyle="mapbox://styles/mapbox/light-v10"
            {...viewport}
          >
        </Map>
    </DeckGL>
  );
};

export default MapComponent;
