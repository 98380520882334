import React from 'react';

const Contact = () => {
  return (
    <div style={{ padding: 20 }}>
      <h1>Contact Us</h1>
      <p>Here is how you can contact us.</p>
    </div>
  );
};

export default Contact;
