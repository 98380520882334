import React from 'react';

const About = () => {
  return (
    <div style={{ padding: 20 }}>
      <h1>About Us</h1>
      <p>This is a simple about us page.</p>
    </div>
  );
};

export default About;
